import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5-5-5-5 to a 5RM`}</p>
    <p>{`Barbell Rows 5-5-5-5 to a 5RM`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 amrap of:`}</p>
    <p>{`15-S2OH (95/65)`}</p>
    <p>{`12-T2B`}</p>
    <p>{`9-KB Snatch (53/35)`}</p>
    <p><em parentName="p">{`*`}{`compare to 6/1/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
Fallscitystrength\\@gmail for more info. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      